import React from "react";
import { HProps } from "../interfaces";
import * as S from "./H3.style";

const H3: React.FC<HProps> = (props) => (
  <S.Text {...props} level={3}>
    {props.children}
  </S.Text>
);

export default H3;
