import { createAsyncThunk } from "@reduxjs/toolkit";
import { axiosInstance } from "@app/api";
import axios from "axios";

const ANOMALY_PATH = process.env.REACT_APP_ANOMALY_PATH

export const detectAnomalies = createAsyncThunk(
    'anomalies/detect',
    async (_, { rejectWithValue }) => {

        const anomalyCancelToken = axios.CancelToken.source();

        try {
            const response = await axiosInstance.post(`${ANOMALY_PATH}/detect`, {}, { cancelToken: anomalyCancelToken.token });
            return response.data;
        } catch (err: any) {
            if (axios.isCancel(err)) {
                console.log('Anomaly request is canceled:', err.message);
            } else {
                return rejectWithValue(err.response?.data || err.message);
            }
        }
    }
);

