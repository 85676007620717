import {createSlice} from '@reduxjs/toolkit';
import {StreamingCamera} from '@common/index';
import {fetchStreamSettings} from '@app/services';

interface IStreamingCameraState {
    streamingCameras: StreamingCamera[];
    selectedStreamingCamera: StreamingCamera | null;
    detectionConfidence: number;
    classificationConfidence: number;
}

const STREAMING_CAMERAS: StreamingCamera[] = [
    {
        name: 'Anadolu Hisarı',
        coordinates: [41.0820728, 29.0672813],
        url: 'https://hls.ibb.gov.tr/ls/cam_turistik/cam_trsk_anadolu_hisari.stream/playlist.m3u8',
    }

];

const initialState: IStreamingCameraState = {
    streamingCameras: STREAMING_CAMERAS,
    selectedStreamingCamera: null,
    detectionConfidence: fetchStreamSettings().detectionConfidence,
    classificationConfidence: fetchStreamSettings().classificationConfidence,
};

export const streamSlice = createSlice({
    name: 'stream',
    initialState,
    reducers: {
        setStreamingCamera(state, action) {
            state.selectedStreamingCamera = action.payload;
        },

        setDetectionConfidence(state, action) {
            state.detectionConfidence = action.payload;
        },

        setClassificationConfidence(state, action) {
            state.classificationConfidence = action.payload;
        },
    },
});

export const {setStreamingCamera, setDetectionConfidence, setClassificationConfidence} = streamSlice.actions;
export default streamSlice.reducer;
