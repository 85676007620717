import { NoData } from '@app/components';
import { themeObject } from '@app/styles/themes/ThemeVariables';
import { generateFilter, generateFilterItem } from '@app/utils';
import {
  AnalyticData,
  ChartType,
  FilterType,
  IMapFilters,
  Theme,
  TrackAnalyticQueryFields,
} from '@common/index';
import { useAppSelector, useAxios } from '@hooks/index';
import React, {SetStateAction, useEffect, useRef, useState, useCallback} from 'react';
import { useTranslation } from 'react-i18next';
import * as S from './AnalyticDataChart.style';
import moment from "moment";
import { ECharts } from 'echarts';
import { useAppDispatch } from '@app/hooks';
import { setFilters } from '@app/store/slices/Filters/slice';
import {NavigationalStatus} from '@app/common/enums';

interface IAnalyticDataChartProps {
  type: ChartType;
  queryBaseUrl?: string;
  trackQueryField: TrackAnalyticQueryFields;
  setLoading: React.Dispatch<SetStateAction<boolean>>;
  yAxisExtractor?: (d: any) => any;
  sortDataEnabled?: boolean;
  fieldFilterName : string;
  selectedFieldsExtractor?: (d:string) => string;

}

const AnalyticDataChart: React.FC<IAnalyticDataChartProps> = ({
  type,
  queryBaseUrl,
  trackQueryField,
  yAxisExtractor,
  selectedFieldsExtractor,
  fieldFilterName,
  setLoading,
  sortDataEnabled= true,
}) => {
  const { t } = useTranslation();

  const MAXIMUM_DATA_LIMIT = 10;
  const MINIMUM_DATA_LIMIT = 5;

  const [isMoreDataOpened, setIsMoreDataOpened] = useState<boolean>(false);
  const [selectedFields, setSelectedFields] = useState<string[]>([]);

  const intervalRef = useRef<NodeJS.Timeout | null>(null);

  const theme: Theme = useAppSelector((state) => state.theme.data);
  const filters: IMapFilters = useAppSelector((state) => state.filters);
  const mapBounds = useAppSelector((state) => state.filters.mapBounds);

  const BASE_PATH = process.env.REACT_APP_BASE_PATH;
  const ANALYTIC_COUNT_ENDPOINT = `${BASE_PATH}/count`;

  const queryURL = queryBaseUrl ? `${queryBaseUrl}` : `${ANALYTIC_COUNT_ENDPOINT}`;

  const {
    data,
    isLoading,
    error,
    triggerRequest: fetchChartData,
  } = useAxios<AnalyticData[]>({
    url: queryURL,
    method: 'post',
  });

  const [dataToPresent, setDataToPresent] = useState<AnalyticData[] | undefined>(data);
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (data && data.length > 0) {
      const filteredData = data
          .filter(d => d.key !== '')
          .map(d => {
            // Check if the key is numeric (if it's a number type)
            const updatedKey = typeof d.key === 'number' && NavigationalStatus[d.key]
                ? NavigationalStatus[d.key]
                : d.key; // Keep the original key if not numeric

            return {
              ...d,
              key: updatedKey,
            };
          });

      setDataToPresent(filteredData);
      //console.log("filtered data", filteredData);
    }
  }, [data]);

  useEffect(() => {
    setLoading(isLoading);
  }, [isLoading]);

  useEffect(() => {

    //console.log("trackQueryField",trackQueryField);

    const filter = generateFilter([
      generateFilterItem(FilterType.COUNT_FIELD, trackQueryField),
      generateFilterItem(FilterType.TIME_RANGE, [moment(filters.timeRange[1]).subtract(15, 'minutes')
          .toISOString(), filters.timeRange[1]]),
      generateFilterItem(FilterType.GEOBOX, [mapBounds.northWest, mapBounds.southEast, filters.timeRange[1]]),
    ]);
    //console.log('filter', filter);


    if (mapBounds.northWest && mapBounds.southEast)
    fetchChartData({ data: filter });

    // Stop previous interval
    if (intervalRef.current) {
      clearInterval(intervalRef.current);
    }

    // Start new interval
    intervalRef.current = setInterval(() => {
      fetchChartData({data: filter});
    }, 10000);

    // Stop the interval when the component unmounts
    return () => {
      if (intervalRef.current) {
        clearInterval(intervalRef.current);
      }
    };
  }, [filters, mapBounds]);

  useEffect(() => {
    const filterFields = selectedFields.map(selectedFieldsExtractor ? selectedFieldsExtractor : (d) => d)
    dispatch(
        setFilters({
          ...filters,
          [fieldFilterName]: filterFields,
        }),
      );
  }, [selectedFields]);

  const sortData = (d1: AnalyticData, d2: AnalyticData) => {
    if (d1.count === d2.count) {
      return 0;
    }
    return d1.count > d2.count ? -1 : 1;
  };

  const generateOption = (chartType: ChartType) => {
    const chartOptions: any = {
      height: '80px',
      responsive: true,
      maintainAspectRatio: false,
      tooltip: {
        trigger: 'axis',
        axisPointer: {
          type: 'shadow',
        },
      },
      grid: {
        left: '3%',
        right: '4%',
        bottom: '1%',
        top: '1%',
        containLabel: true,
      },
    };

    switch (chartType) {
      case ChartType.HORIZONTAL_BAR:
        const chartData = dataToPresent
            ? sortDataEnabled
                ? [...dataToPresent].sort(sortData)
                : [...dataToPresent]
            : [];

        chartOptions['xAxis'] = {
          type: 'value',
          nameLocation: 'left',
          axisLine: { show: false },
          axisLabel: { show: false },
          axisTick: { show: false },
          splitLine: { show: false },
        };

        chartOptions['yAxis'] = {
          type: 'category',
          axisLine: { show: false },
          axisTick: { show: false },

          data: chartData
            .slice(0, isMoreDataOpened ? MAXIMUM_DATA_LIMIT : MINIMUM_DATA_LIMIT)
            .map(yAxisExtractor ? yAxisExtractor : (d) => d.key)
            .reverse(),
        };

        chartOptions['series'] = {
          type: 'bar',
          data: chartData
            .slice(0, isMoreDataOpened ? MAXIMUM_DATA_LIMIT : MINIMUM_DATA_LIMIT)
            .map((d, idx) => ({ ["value"]: d.count, ["itemStyle"]: {["opacity"]: 1}}))
            .reverse(),

          barWidth: 10,
          barGap: 1,
          itemStyle: {
            borderRadius: [5, 5, 5, 5],
          },
          showBackground: true,
          backgroundStyle: {
            color: themeObject[theme].chartBarBackgroundColor,
            borderRadius: [5, 5, 5, 5],
          },
        };

        break;
    }


    if(selectedFields.length > 0) {
        //check if data contains any of the selectedFields
        let isAnyFound = false;
        chartOptions['yAxis'].data.forEach((field:string, idx:number) => {
            let index = selectedFields.indexOf(field);
            if(index !== -1) {
                isAnyFound = true;
            }
        });

        if(isAnyFound) {
          chartOptions['yAxis'].data.forEach((field:string, idx:number) => {
            let index = selectedFields.indexOf(field);
            if(index === -1) {
                chartOptions['series'].data[idx].itemStyle.opacity = 0.4;
            }
          });
        } else {
          setSelectedFields([]);
        }
    }

    return chartOptions;
  };

  const onChartReadyCallback = (obj:ECharts) => {

      obj.on('click', function(params) {

         handleSelectedField(params.name);
      });

    obj.getZr().on('click', function(event) {
      // No "target" means that mouse/pointer is not on
      // any of the graphic elements, which is "blank".

      var pointInPixel = [event.offsetX, event.offsetY];
        var pointInGrid = obj.convertFromPixel('grid', pointInPixel);
       /* var category = obj.getOption()['xAxis'][0].data[pointInGrid[0]]*/
      getSelectedFieldFromIndex(pointInGrid[1]);
      if (!event.target) {
        // Click on blank. Do something.
      }
    });




  };

const getSelectedFieldFromIndex = useCallback((index:number) => {


    const chartData = dataToPresent
                ? sortDataEnabled
                    ? [...dataToPresent].sort(sortData)
                    : [...dataToPresent]
                : [];


    const  data =  chartData.slice(0, isMoreDataOpened ? MAXIMUM_DATA_LIMIT : MINIMUM_DATA_LIMIT)
                      .map(yAxisExtractor ? yAxisExtractor : (d) => d.key)
                      .reverse();


    handleSelectedField(data[index]);

  }, [dataToPresent]);

  const handleSelectedField = (field:string) => {
      setSelectedFields((prevState) => {
          let copyItems = [...prevState]

          if(copyItems.includes(field)) {
               copyItems = copyItems.filter(a => a !== field);

          } else {
                 copyItems.push(field);
           }
           return [...copyItems]
        });
  };

  const echartRef = useRef<any>();

    useEffect(() => {
        if(echartRef.current != null) {
          const echart:ECharts = echartRef.current!.getEchartsInstance();
          echart.getZr().off('click');
              echart.getZr().on('click', function(event) {
                                  // No "target" means that mouse/pointer is not on
                                  // any of the graphic elements, which is "blank".

                                  var pointInPixel = [event.offsetX, event.offsetY];
                                    var pointInGrid = echart.convertFromPixel('grid', pointInPixel);
                                   /* var category = obj.getOption()['xAxis'][0].data[pointInGrid[0]]*/
                                  getSelectedFieldFromIndex(pointInGrid[1]);
                                  if (!event.target) {
                                    // Click on blank. Do something.
                                  }
                                });
          }
    }, [dataToPresent]);




  const renderChart = () => {
    if (Array.isArray(dataToPresent) && dataToPresent.length > 0) {
      const chartOptions = generateOption(type);

      return (
        <S.EChartContainer>

          <S.EChart ref={echartRef} option={chartOptions} />
        </S.EChartContainer>
      );
    }

    return <NoData />;
  };

  return renderChart();
};

export default AnalyticDataChart;
