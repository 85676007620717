import React, { useEffect, useState } from 'react';
import { Modal, Input, Switch, Tooltip } from 'antd';
import { toggleROIenabled, getROIenabled } from '@store/slices/UserContent/thunks';
import { useAuth } from '@app/context/AuthContext';
import { useAppDispatch } from '@app/hooks';
import { QuestionCircleOutlined } from '@ant-design/icons';

interface EditROIModalProps {
    visible: boolean;
    editedROIName: string;
    currentROIName: string;
    setEditedROIName: (name: string) => void;
    handleUpdateROI: () => void;
    onCancel: () => void;
}

const EditROIModal: React.FC<EditROIModalProps> = ({
                                                       visible,
                                                       editedROIName,
                                                       currentROIName,
                                                       setEditedROIName,
                                                       handleUpdateROI,
                                                       onCancel
                                                   }) => {
    const [isActivated, setIsActivated] = useState(true);
    const { currentUser } = useAuth();
    const userEmail = currentUser?.email || '';
    const dispatch = useAppDispatch();

    useEffect(() => {
        const fetchROIenabledStatus = async () => {
            try {
                if (editedROIName) {
                    const resultAction = await dispatch(getROIenabled({ email: userEmail, roiName: currentROIName }));

                    if (getROIenabled.fulfilled.match(resultAction)) {
                        const isEnabled = resultAction.payload;
                        setIsActivated(isEnabled);
                    } else {
                        const error = resultAction.payload as string;
                        console.error('Failed to fetch ROI enabled status:', error);
                        setIsActivated(false);
                    }
                }
            } catch (error) {
                console.error('Failed to fetch ROI enabled status:', error);
                setIsActivated(false);
            }
        };

        fetchROIenabledStatus();
    }, [dispatch, userEmail, editedROIName]);

    const handleSwitchChange = (checked: boolean) => {
        setIsActivated(checked);
        dispatch(toggleROIenabled({ email: userEmail, roiName: editedROIName }));
    };

    return (
        <Modal
            title="Edit Region Of Interest"
            visible={visible}
            onOk={handleUpdateROI}
            onCancel={onCancel}
            okText="Save"
            cancelText="Cancel"
        >
            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', marginBottom: 16 }}>
                {/* Add margin to the span to create space */}
                <span >Activate the ROI:</span>
                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start' }}>
                    <Switch
                        checked={isActivated}
                        onChange={handleSwitchChange}
                        checkedChildren="On"
                        unCheckedChildren="Off"
                        style={{ backgroundColor: isActivated ? 'green' : 'gray', marginRight: 235 }}
                    />
                    <Tooltip title="Do you want us to detect anomalies in this region of interest?">
                        <QuestionCircleOutlined style={{ color: '#1890ff' }} />
                    </Tooltip>
                </div>
            </div>

            <div style={{ display: 'flex', alignItems: 'center', marginTop: 16 }}>
                <span style={{ marginRight: 8, width: '160px' }}>Change the name:</span>
                <Input
                    placeholder="ROI Name"
                    value={editedROIName}
                    onChange={(e) => setEditedROIName(e.target.value)}
                    style={{ flex: 1 }}
                />
            </div>
        </Modal>
    );
};

export default EditROIModal;
