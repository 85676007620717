import { IMapFilters } from '@app/common';
import { createSlice } from '@reduxjs/toolkit';
import { fetchFilters } from '@app/services';

const initialState: IMapFilters = fetchFilters();

export const filtersSlice = createSlice({
  name: 'filters',
  initialState,
  reducers: {
    setFilters: (state, action) => {
      state.timeRange = action.payload.timeRange;
      state.trackLast = action.payload.trackLast;
      state.heatmapLast = action.payload.heatmapLast;
      state.vesselTypes = action.payload.vesselTypes;
      state.flags = action.payload.flags;
      state.mapBounds = action.payload.mapBounds;
      state.destinations = action.payload.destinations;
      state.speeds = action.payload.speeds;
      state.navigationalStatuses = action.payload.navigationalStatuses;
    },
    setTimeRangeFilter: (state, action) => {
      state.timeRange = action.payload;
    },
  },
});

export const { setFilters, setTimeRangeFilter } = filtersSlice.actions;
export default filtersSlice.reducer;
