import { Layout } from "antd";
import { useState, useEffect } from 'react';
import AccountOverview from './AccountOverview/AccountOverview';
import FleetOverview from './FleetOverview/FleetOverview';
import FleetView from './FleetView/FleetView';
import FleetAddModal from './FleetAddModal/FleetAddModal';
import FleetEditModal from './FleetEditModal/FleetEditModal';
import CustomMenu from './CustomMenu/CustomMenu';
import CustomSider from './CustomSider/CustomSider';
import CustomContent from './CustomContent/CustomContent';
import axios from 'axios';
import ROIList from './ROIList/ROIList';
import {useAppDispatch} from '@app/hooks';
import {useAuth} from "@app/context/AuthContext";
import {setRoiCount} from "@store/slices/UserContent/slice";
import {fetchAllROIs, editROI, deleteROI, fetchFleetsByEmail, updateFleet, addFleet, deleteFleet, addVesselToFleet, deleteVesselFromFleet} from '@store/slices/UserContent/thunks';
import {clearCurrentRegionOfInterest} from "@store/slices/ROI/roiSlice";
import ChangePassword from "@pages/AccountPage/ChangePassword/ChangePassword";
import ChangeFullNameForm from "@pages/AccountPage/ChangeFullName/ChangeFullName";
import {RegionOfInterest} from "@app/common";
import {Fleet, User} from "@app/common";


const AccountPage = () => {
    const [selectedKey, setSelectedKey] = useState("overview");
    const [fleetUsage, setFleetUsage] = useState(0);
    const [fleets, setFleets] = useState<Fleet[]>([]);
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [newFleetName, setNewFleetName] = useState("");
    const [activeFleet, setActiveFleet] = useState<Fleet | null>(null);
    const [isEditModalVisible, setIsEditModalVisible] = useState(false);
    const [newVesselMmsi, setNewVesselMmsi] = useState<number | undefined>(undefined);
    const [selectedFleet, setSelectedFleet] = useState<Fleet | null>(null);
    const [newFleetColor, setNewFleetColor] = useState('#000000');
    const { currentUser } = useAuth();
    const [ROIs, setROIs] = useState<RegionOfInterest[]>([]);
    const dispatch = useAppDispatch();
    const userEmail = currentUser?.email ? currentUser.email : "";

    useEffect(() => {
        if(ROIs) {
            dispatch(setRoiCount(ROIs.length))
        }
    }, [ROIs]);

    const { getAccountCreationDate } = useAuth();
    const accountCreationDate = getAccountCreationDate();
    const formattedAccountCreationDate = accountCreationDate
        ? new Date(accountCreationDate).toLocaleDateString('en-GB', {
            day: '2-digit',
            month: 'short',
            year: 'numeric',
        })
        : null;



    useEffect(() => {

        dispatch(fetchFleetsByEmail({email: userEmail}))
            .unwrap()
            .then((data)=>{

                setFleets(data);
                setFleetUsage(data.length);
            })


    }, [dispatch, userEmail]);
    

    const handleUpdateFleet = (editedFleet, updatedFleet) => {



        const updatedFleetData = {
            email: userEmail,
            fleetName: editedFleet.name,
            fleetData: {
                color: updatedFleet.color,
                name: updatedFleet.name,
            }
        };

        dispatch(updateFleet(updatedFleetData))
            .unwrap()
            .then(response => {

                const updatedFleet = response.data;
                const updatedFleets = fleets.map(fleet =>
                    fleet.name === updatedFleet.name ? updatedFleet : fleet
                );
                setFleets(updatedFleets);
                setSelectedFleet(null);
                setNewFleetName("");
                setNewFleetColor("#000000");
                setIsEditModalVisible(false);
            })
            .catch(error => {
                if (error.response) {

                    console.error("Response error:", error.response.data);
                    console.error("Response status:", error.response.status);
                    console.error("Response headers:", error.response.headers);
                } else if (error.request) {

                    console.error("Request error:", error.request);
                } else {

                    console.error("Error message:", error.message);
                }
            });

    };



    function handleFleetUsage() {
        setFleetUsage(fleetUsage => {
            if (fleetUsage >= 5) {

                return fleetUsage;
            }
            return fleetUsage + 1;
        });
    }

    function handleAddFleet() {

        if (fleetUsage >= 5) {
            return;
        }

        if (newFleetName.trim()) {

            const newFleet = {
                color: newFleetColor,
                name: newFleetName,
                vessels: []
            };

            const requestBody = {
                email: userEmail,
                fleetData: newFleet
            };

            dispatch(addFleet(requestBody))
                .unwrap()
                .then(response => {

                    const updatedFleet = response.data;
                    const updatedFleets = [...fleets, updatedFleet];

                    setFleets(updatedFleets);
                    setNewFleetName("");
                    setNewFleetColor("#000000");
                    setIsModalVisible(false);
                    handleFleetUsage();

                })
                .catch(error => {
                    console.error("Error adding fleet:", error);
                });
        }
    }

    function handleViewFleet(fleet: Fleet) {

        setActiveFleet(fleet);
        setSelectedKey("fleet_view");
    }

    function handleEditFleet(fleet: Fleet) {
        setSelectedFleet(fleet);
        setIsEditModalVisible(true);
    }


    async function handleDeleteFleet(index: number, userEmail: string) {
        const fleetToDelete = fleets[index];
        const requested_body = {
            email: userEmail,
            fleetName: fleetToDelete.name,
        }
        try {
            dispatch(deleteFleet(requested_body))
                .unwrap()
                .then((response)=> {
                    const updatedFleets = [...fleets];
                    updatedFleets.splice(index, 1);
                    setFleetUsage(fleetUsage - 1);
                    setFleets(updatedFleets);
                })

        } catch (error) {
            console.error('Error deleting fleet:', error);
        }
    }
    function handleAddVesselToFleet() {

        if (selectedFleet && newVesselMmsi) {
            const requestBody = {
                mmsi: newVesselMmsi.toString()
            };
            const dispatch_body = {
                email: userEmail,
                fleetName: selectedFleet.name,
                vesselMmsi: newVesselMmsi.toString()
            }
            dispatch(addVesselToFleet(dispatch_body))
                .unwrap()
                .then(response => {

                    const updatedFleet = {
                        ...selectedFleet,
                        vessels: [...selectedFleet.vessels, requestBody]
                    };

                    const updatedFleets = fleets.map(fleet => fleet.name === selectedFleet.name ? updatedFleet : fleet);
                    setFleets(updatedFleets);
                    setIsEditModalVisible(false);
                    setNewVesselMmsi(undefined);

                })
                .catch(error => {
                    console.error("Error adding vessel to fleet:", error);
                });
        }
    }
    const handleDeleteVesselFromFleet = async (fleetName: string, vesselMmsi: string) => {
        try {
            const requestConfig = {
                params: {
                    email: user?.email ?? '',
                    name: fleetName,
                },
                data: {
                    vesselMmsi: vesselMmsi,
                },
            };
            const request_body = {
                email: userEmail,
                fleetName: fleetName,
                vesselMmsi: vesselMmsi
            }

            await dispatch(deleteVesselFromFleet(request_body));


            setFleets((prevFleets) =>
                prevFleets.map((fleet) =>
                    fleet.name === fleetName
                        ? { ...fleet, vessels: fleet.vessels.filter((vessel) => vessel.mmsi !== vesselMmsi) }
                        : fleet
                )
            );

            if (activeFleet && activeFleet.name === fleetName) {
                setActiveFleet({
                    ...activeFleet,
                    vessels: activeFleet.vessels.filter((vessel) => vessel.mmsi !== vesselMmsi),
                });
            }
        } catch (error) {
            console.error('Error deleting vessel from fleet:', error);
        }
    };


    const user: User = {

        email: userEmail,
        accountCreatedAt: formattedAccountCreationDate ,
        fleets: [],
        ROIs: []
    }


    useEffect(() => {
        if (selectedKey === 'roi') {
            const cancelTokenSource = axios.CancelToken.source();


            dispatch(fetchAllROIs({email: userEmail ,cancelToken: cancelTokenSource.token }))
                .unwrap()
                .then((data) => {


                    setROIs(data);
                })
                .catch((err) => {
                    console.error("Error fetching ROIs:", err);
                });


            return () => {
                cancelTokenSource.cancel();
            };
        }
    }, [selectedKey, dispatch]);


    const handleEditROI = async (newName: string, oldName: string) => {
        try {
            await dispatch(editROI({
                email: userEmail,
                currentROIName: oldName,
                newROIName: newName
            })).unwrap();
            setROIs(ROIs.map(roi => roi.name === oldName ? {...roi, name: newName} : roi));
        }catch (error) {
            console.error("Error updating ROI:", error);
        }

    };

    const handleDeleteROI = async (name: string) => {
        try {
            const resultAction = await dispatch(deleteROI({email: userEmail, roiName: name }));
            if (deleteROI.fulfilled.match(resultAction)) {

                setROIs(ROIs.filter(roi => roi.name !== name));
            } else {
                console.error("Failed to delete ROI:", resultAction.payload);
            }
        } catch (err) {
            console.error("Error deleting ROI:", err);
        }
    };


    useEffect(() => {

        return () => {
            dispatch(clearCurrentRegionOfInterest());
        };
    }, []);
    const renderContent = () => {
        switch (selectedKey) {
            case "overview":

                return (

                    <div>
                        <AccountOverview user={user} onViewFleets={() => setSelectedKey('my_fleets')} onViewROIs={() => setSelectedKey("roi")}/>
                        <ChangeFullNameForm/>
                        <ChangePassword/>
                    </div>

                );
            case "my_fleets":
                return (
                    <FleetOverview
                        fleets={fleets}
                        fleetUsage={fleetUsage}
                        setIsModalVisible={setIsModalVisible}
                        onAddFleet={handleAddFleet}
                        onViewFleet={handleViewFleet}
                        onUpdateFleet={handleUpdateFleet}
                        onEditFleet={handleEditFleet}
                        onDeleteFleet={handleDeleteFleet}
                        onDeleteVessel={handleDeleteVesselFromFleet}
                        userEmail={user?.email ?? ''}
                    />
                );
            case "fleet_view":
                return <FleetView
                    fleet={activeFleet}
                    onBack={() => setSelectedKey('my_fleets')}
                    onDeleteVessel={handleDeleteVesselFromFleet}
                />;

            case "roi":
                return (
                    <ROIList
                        ROIs={ROIs}
                        onROINameClick={(name) => console.log(`ROI ${name} clicked`)}
                        handleEditROI={(oldName, newName) => handleEditROI(newName, oldName)}
                        handleDeleteROI={handleDeleteROI}
                    />
                );
            default:
                return <AccountOverview user={user} onViewFleets={() => setSelectedKey('my_fleets')} onViewROIs={() => setSelectedKey("roi")}/>;
        }
    };

    return (
        <Layout>
            <CustomSider>
                <CustomMenu selectedKey={selectedKey} onSelect={setSelectedKey} />
            </CustomSider>
            <Layout>
                <CustomContent>{renderContent()}</CustomContent>
            </Layout>
            <FleetAddModal
                isVisible={isModalVisible}
                onOk={handleAddFleet}
                onCancel={() => setIsModalVisible(false)}
                newFleetName={newFleetName}
                newFleetColor={newFleetColor}
                onFleetNameChange={(e) => setNewFleetName(e.target.value)}
                onColorChange={(e) => setNewFleetColor(e.target.value)}
            />
            <FleetEditModal
                isVisible={isEditModalVisible}
                onOk={handleAddVesselToFleet}
                onCancel={() => setIsEditModalVisible(false)}
                newVesselMmsi={newVesselMmsi}
                onVesselMmsiChange={(e) => setNewVesselMmsi(parseInt(e.target.value) || undefined)}
            />
        </Layout>
    );
};


export default AccountPage;
