import {BORDER_RADIUS, FONT_SIZE} from "@app/common";

import styled from "styled-components";
import ReactCountryFlag from "react-country-flag";

import UnknownFlagSVG from "@assets/images/unknownFlag.svg";

export const CountryFlag = styled(ReactCountryFlag)`
  font-size: ${FONT_SIZE.xl} !important;
`;


export const UnknownFlag = styled.img.attrs({ src: UnknownFlagSVG })`
  width: 1.25rem;
  height: 1.25rem;
`;