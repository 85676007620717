import { CloseOutlined } from '@ant-design/icons';
import { Countries, Track } from '@app/common';
import { H3, Space } from '@components/index';
import {useAppDispatch} from '@app/hooks';
import { Dates } from '@app/services';
import { setSelectedMapTrackId } from '@store/slices/Tracks/slice';
import { Col, Row, Tooltip, Button, Modal, Select, message } from 'antd';
import L from 'leaflet';
import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import * as S from './VesselCard.style';
import {fetchFleetsByEmail, addVesselToFleet, getFleetByName} from "@store/slices/UserContent/thunks";
import {useAuth} from "@app/context/AuthContext";
import {NavigationalStatus} from "@common/enums";

const { Option } = Select;

interface IVesselCardProps {
  track: Track;
  direction: 'vertical' | 'horizontal';
}

const VesselCard: React.FC<IVesselCardProps> = ({ track, direction }) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const IMAGE_SERVER_PATH = process.env.REACT_APP_IMAGE_SERVER_PATH;
  const [selectedVessel, setSelectedVessel] = useState<Track | null>(null);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [fleets, setFleets] = useState<any[]>([]);
  const [selectedFleet, setSelectedFleet] = useState<string | null>(null);
  const verticalCardRef = useRef<HTMLDivElement>(null);
  const {currentUser} = useAuth();
  const userEmail = currentUser?.email ? currentUser.email : "";
  useEffect(() => {
    if (verticalCardRef.current) {
      const cardElement = verticalCardRef.current;
      L.DomEvent.disableClickPropagation(cardElement);
      L.DomEvent.disableScrollPropagation(cardElement);
    }
  }, []);

  const handleAddToFleet = async (track: Track) => {
    setSelectedVessel(track);
    try {
      dispatch(fetchFleetsByEmail({email: userEmail}))
          .unwrap()
          .then((data)=> {
            setFleets(data);
            setIsModalVisible(true);
          })

    } catch (error) {
      console.error('Error fetching fleets:', error);
      message.error('Error fetching fleets');
    }
  };

  const handleAddVesselToFleet = async () => {
    if (selectedVessel && selectedFleet) {
      try {
        const fleetData = await dispatch(getFleetByName({email: userEmail, fleetName: selectedFleet })).unwrap();
        const vesselExists = fleetData.vessels.some((vessel: { mmsi: string }) => vessel.mmsi === selectedVessel.mmsi);
        if (vesselExists) {
          message.warning('Vessel is already added to the selected fleet');
        } else {

          dispatch(addVesselToFleet({
            email: userEmail,
            fleetName: selectedFleet,
            vesselMmsi: selectedVessel.mmsi
          }));
          message.success('Vessel added to fleet successfully');
        }
      } catch (error) {
        console.error('Error adding vessel to fleet:', error);
        message.error('Error adding vessel to fleet');
      }
    }
    setIsModalVisible(false);
  };


  const generateInfoItem = (title: string, value: string | number) => {
    return (
        <Row justify="space-between">
          <S.InformationText>{title}</S.InformationText>
          <S.InformationText>{value}</S.InformationText>
        </Row>
    );
  };

  const getVesselFlag = (countryCode: string | undefined) => {

    const unknownFlagOutput = (
        <Tooltip title={t('common.unknown')}>
          <S.UnknownFlag />
        </Tooltip>
    );

    if (!countryCode) {
      return unknownFlagOutput;
    }

    const countryName = Countries[countryCode as keyof typeof Countries];

    return countryName ? (
        <Tooltip title={countryName}>
          <S.CountryFlag svg countryCode={countryCode} />
        </Tooltip>
    ) : (
        unknownFlagOutput
    );
  };

  const onCancelHandler = () => {
    dispatch(setSelectedMapTrackId(undefined));
  };

  const renderVertical = () => {
    return (
        <S.VerticalCardContainer ref={verticalCardRef}>
          <S.VerticalCard padding={[18, 18]}>
            <Space direction="vertical">
              <S.VerticalCardVesselImageContainer>
                <S.VerticalCardVesselImage src={`${IMAGE_SERVER_PATH}/vessel?mmsi=${track.mmsi}`} />
                <S.VerticalCardCloseButtonContainer>
                  <S.CloseButton icon={<CloseOutlined />} onClick={onCancelHandler} />
                </S.VerticalCardCloseButtonContainer>
              </S.VerticalCardVesselImageContainer>
              <Col>
                <Row justify="center">
                  <Space>
                    {getVesselFlag(track.flag)}
                    <H3>{track.name}</H3>
                  </Space>
                </Row>
                <Row justify={'center'}>
                  <S.ItalicText>{Dates.getDate(track.timestamp).format('LL HH:mm:ss')}</S.ItalicText>
                </Row>
              </Col>
              <Col>
                {generateInfoItem(t('pages.home.mmsi'), track.mmsi)}
                {generateInfoItem(t('pages.home.imo'), track.imo)}
                {generateInfoItem(t('pages.home.callSign'), track.callsign)}
                {generateInfoItem(t('pages.home.type'), track.vesselType)}
                {generateInfoItem(t('pages.home.speed'), track.speed)}
                {generateInfoItem(t('pages.home.course'), track.course)}
                {/* Generate an info item for the vessel's Navigational Status,
                converting the numeric status to its corresponding label */}
                {generateInfoItem(t('pages.home.navigationalStatus'), NavigationalStatus[track.navigationalStatus])}
              </Col>
              <Col>
                <Button type="primary" onClick={() => handleAddToFleet(track)}>
                  {t('Add to Fleet')}
                </Button>
              </Col>
            </Space>
            <Modal
                title="Add Vessel to Fleet"
                visible={isModalVisible}
                onOk={handleAddVesselToFleet}
                onCancel={() => setIsModalVisible(false)}
            >
              <p>Select a fleet to add the vessel to:</p>
              <Select
                  style={{ width: '100%' }}
                  placeholder="Select a fleet"
                  onChange={(value) => setSelectedFleet(value)}
              >
                {fleets.map((fleet) => (
                    <Option key={fleet.name} value={fleet.name}>
                      {fleet.name}
                    </Option>
                ))}
              </Select>
            </Modal>
          </S.VerticalCard>
        </S.VerticalCardContainer>
    );
  };

  const renderHorizontal = () => {
    return (
        <S.HorizontalCard padding={[18, 18]}>
          <Row gutter={[12, 12]}>
            <Col span={12}>
              <S.HorizontalCardVesselImageContainer>
                <S.HorizontalCardVesselImage src={`${IMAGE_SERVER_PATH}/vessel?mmsi=${track.mmsi}`} />
              </S.HorizontalCardVesselImageContainer>
            </Col>
            <Col span={12}>
              <Space direction="vertical">
                <Col>
                  <Row justify="space-between" align="middle">
                    <Space>
                      {getVesselFlag(track.flag)}
                      <H3>{track.name}</H3>
                    </Space>
                    <S.CloseButton icon={<CloseOutlined />} onClick={onCancelHandler} />
                  </Row>

                  <S.ItalicText>{Dates.getDate(track.timestamp).format('LL HH:mm:ss')}</S.ItalicText>
                </Col>

                <Row>
                  <Col span={24}>
                    {generateInfoItem(t('pages.home.mmsi'), track.mmsi)}
                    {generateInfoItem(t('pages.home.imo'), track.imo)}
                    {generateInfoItem(t('pages.home.callSign'), track.callsign)}
                    {generateInfoItem(t('pages.home.type'), track.vesselType)}
                    {generateInfoItem(t('pages.home.speed'), track.speed)}
                    {generateInfoItem(t('pages.home.course'), track.course)}
                    {/* Generate an info item for the vessel's Navigational Status,
                    converting the numeric status to its corresponding label */}
                    {generateInfoItem(t('pages.home.navigationalStatus'), NavigationalStatus[track.navigationalStatus])}
                  </Col>
                </Row>
                <Col>
                  <Button type="primary" onClick={() => handleAddToFleet(track)}>
                    {t('pages.home.addToFleet')}
                  </Button>
                </Col>
              </Space>
            </Col>
          </Row>
          <Modal
              title="Add Vessel to Fleet"
              visible={isModalVisible}
              onOk={handleAddVesselToFleet}
              onCancel={() => setIsModalVisible(false)}
          >
            <p>Select a fleet to add the vessel to:</p>
            <Select
                style={{ width: '100%' }}
                placeholder="Select a fleet"
                onChange={(value) => setSelectedFleet(value)}
            >
              {fleets.map((fleet) => (
                  <Option key={fleet.name} value={fleet.name}>
                    {fleet.name}
                  </Option>
              ))}
            </Select>
          </Modal>
        </S.HorizontalCard>
    );
  };

  return direction === 'vertical' ? renderVertical() : renderHorizontal();
};

export default VesselCard;
