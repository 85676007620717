// StyledComponents.js (or .ts if you're using TypeScript)

import styled from 'styled-components';
import { Button } from 'antd';

// Exporting the styled SquareButton component
export const SquareButton = styled(Button)`
    width: 41px;  // Adjust the size to fit your needs
    height: 41px;
    position: relative;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    background-color: #ffffff;
    border-radius: 10px; 
    border-color: #B5BFB5;
    
    padding: 0;
    margin-right: 5px;
    &:hover {
        background-color: #ffffff;
        box-shadow: none;
    }
    &:active {
        background-color: #ffffff;  
        box-shadow: none;
    }

    &:focus {
        background-color: #ffffff;  
        box-shadow: none;
    }
`;
