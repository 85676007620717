import { Countries, FilterType, ITrackFilter, Language } from '@common/index';
import enUS from 'antd/lib/locale/en_US';
import trTR from 'antd/lib/locale/tr_TR';

export const getLocale = (language: Language) => {
  switch (language) {
    case 'tr':
      return trTR;
    case 'en':
    default:
      return enUS;
  }
};

export const getBrowserLanguage = (): Language => {
  const language = navigator.language;

  switch (language) {
    case 'tr':
      return 'tr';
    case 'en-US':
    default:
      return 'en';
  }
};

export const getKeyFromEnumValue = (enumType: any | undefined, value: string | number): string | undefined => {
  for (const key in enumType) {
    if (enumType[key] === value) {
      return key;
    }
  }
  return undefined;
};

export const convertCoordinateArrayToString = (coordinate: [[number, number], [number, number]]) => {
  return `[[${coordinate[0][0]}, ${coordinate[0][1]}], [${coordinate[1][0]}, ${coordinate[1][1]}]]`;
};

export const isCoordinateInBoundaries = (
  northWest: [number, number] | null,
  southEast: [number, number] | null,
  coordinate: [number, number] | null,
) => {
  if (!northWest || !southEast || !coordinate) {
    return false;
  }

  if (coordinate[0] <= northWest[0] && coordinate[0] >= southEast[0]) {
    if (coordinate[1] >= northWest[1] && coordinate[1] <= southEast[1]) {
      return true;
    }
  }

  return false;
};

export const decimalToDMS = (coordinate: number, isLatitude: boolean, t: (keyword: string) => string) => {
  var absolute = Math.abs(coordinate);
  var degrees = Math.floor(absolute);
  var minutes = (absolute - degrees) * 60;
  var seconds = (minutes - Math.floor(minutes)) * 60;
  if (isLatitude) {
    var direction = coordinate >= 0 ? t('units.N') : t('units.S');
  } else {
    var direction = coordinate >= 0 ? t('units.E') : t('units.W');
  }
  return degrees + '° ' + Math.floor(minutes) + "' " + seconds.toFixed(2) + "'' " + direction;
};

export const generateFilter = (filterItems: ITrackFilter[]) => {
  return {
    filters: [...filterItems],
  };
};

export const generateFilterItem = (type: FilterType, payload: any): ITrackFilter => {
  return {
    type: type,
    payload: payload
  };
};

