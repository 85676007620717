import {Icon} from "@iconify/react";
import { Button, Popover } from 'antd';

import React from 'react';
import {useState} from "react";
import ShowAnomalyModal from "@pages/HomePage/components/AnomaliesModal/ShowAnomalyModal/ShowAnomalyModal";
import * as S from "./AnomaliesModal.style"
const AnomaliesModal: React.FC = () => {
    const [isModalVisible, setIsModalVisible] = useState<boolean>(false);

    const handleShowAnomalies = () => {
        setIsModalVisible(true);
    }
    const onCancel = () => {
        setIsModalVisible(false);
    }
    return (
        <div>
            <Popover
                content={<div style={{ fontSize: '15px', width: '130px', height: '13px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>Anomaly Vessels</div>}
                title={null}
                trigger="hover"
                mouseLeaveDelay={0.1}
                placement="left"
            >
           <S.SquareButton type="primary" onClick={handleShowAnomalies}>
               <Icon icon="mingcute:ship-fill" height={25} color="#2B312B" />
               <Icon
                   icon= "octicon:alert-16"
                   style={{
                       position: 'absolute',
                       top: '2px',
                       right: '2px',
                       width: '18px',
                       height: '17px',
                       fontSize: '40px',
                       color: '#2B312B',
                       backgroundColor: "#ffffff",
                       borderRadius: '60%',
                       padding: '2px',
                       border: "#01509A",

                   }}
               />
           </S.SquareButton>
            </Popover>
           <ShowAnomalyModal onCancel={onCancel} visible={isModalVisible} setVisible={setIsModalVisible}/>
        </div>
    );
};

export default AnomaliesModal;
