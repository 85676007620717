import { Row } from "antd";
import styled, { css } from "styled-components";

interface IWrapperRowProps {
  $customMargin?: string;
}

export const WrapperRow = styled(Row)<IWrapperRowProps>`
  ${(props) =>
    props.$customMargin
      ? css`
          margin: ${props.$customMargin};
        `
      : css`
          margin-bottom: 1.125rem;
        `}
`;
