import React from "react";
import { PProps } from "../interfaces";
import * as S from "./P2.style";

const P2: React.FC<PProps> = ({ className, children, ...props }) => (
  <S.Text {...props} className={className}>
    {children}
  </S.Text>
);

export default P2;
