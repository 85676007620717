// Loading.tsx
import React, { useState, useEffect } from 'react';
import { BaseForm, H3, Slider, Space } from '@components/index';

interface LoadingProps {
    isLoading: boolean; // Prop to determine if loading state is active
}

const Loading: React.FC<LoadingProps> = ({ isLoading }) => {
    const [loadingText, setLoadingText] = useState<string>('Loading.'); // State to hold the loading text

    useEffect(() => {
        let interval: NodeJS.Timeout | undefined; // Variable to hold the interval ID

        if (isLoading) {
            // If loading is active, set up an interval to update the loading text
            interval = setInterval(() => {
                setLoadingText(prev => {
                    // Cycle through loading text states
                    if (prev === 'Loading.') return 'Loading..'; // Add a dot
                    if (prev === 'Loading..') return 'Loading...'; // Add another dot
                    return 'Loading.'; // Reset to initial state
                });
            }, 500); // Update every 500 milliseconds
        } else {
            setLoadingText(''); // Clear loading text when not loading
        }

        return () => {
            // Cleanup function to clear the interval on component unmount
            if (interval) {
                clearInterval(interval);
            }
        };
    }, [isLoading]); // Effect runs when isLoading prop changes

    return (
        <H3 style={{ visibility: isLoading ? "visible" : 'hidden', height: '1em' }}>
            {isLoading ? loadingText : "Loading Completed"}
        </H3>// Display loading text or completion message based on loading state
    );
};

export default Loading;
