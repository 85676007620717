import React, { useState, useEffect } from 'react';
import { Modal, Button, Tooltip } from 'antd';
import {EyeOutlined} from '@ant-design/icons';
import axios, { CancelTokenSource } from 'axios';
import moment from 'moment';
import { Table } from 'antd';
import {fetchMapTracks} from '@store/slices/Tracks/thunks';
import * as S from './ShowAnomalyModal.style'; 
import {Countries, FetchTracksForm, IMapFilters, IMapSettings} from '@app/common';
import {useAppSelector, useAppDispatch} from "@app/hooks";
import {Vessel} from "@app/common";
import {setSelectedMapTrackId} from "@store/slices/Tracks/slice";
import {setMapSettings} from "@store/slices/MapSettings/slice";
interface ShowAnomalyModalProps {
    onCancel: () => void;
    visible: boolean;
    setVisible

}

const ShowAnomalyModal: React.FC<ShowAnomalyModalProps> = ({onCancel, visible, setVisible}) => {
    const [vesselData, setVesselData] = useState<Vessel[]>([]);
    const [loading, setLoading] = useState<boolean>(false);
    const IMAGE_SERVER_PATH = process.env.REACT_APP_IMAGE_SERVER_PATH;
    const mapFilters: IMapFilters = useAppSelector((state) => state.filters);
    const dispatch = useAppDispatch();
    const anomalies = useAppSelector((state) => state.anomalyDetection.anomaliesOnMap)
    useAppSelector((state) => state.tracks.mapTracks.selectedTrackId);
    const mapSettings: IMapSettings = useAppSelector((state) => state.mapSettings);
    useEffect(() => {
        if(visible){
        let cancelToken: CancelTokenSource;
        setVesselData([]);
        const fetchVesselData = async () => {
            try {
                setLoading(true);
                cancelToken = axios.CancelToken.source();
                const OneYearAgo = moment(mapFilters.timeRange[1]).subtract(1, 'year');
                const fetchMapTracksForm: FetchTracksForm = {
                    timeRange: [OneYearAgo.toISOString(), mapFilters.timeRange[1]],
                    geoBox: [[89.9, -179.9], [-89.9, 179.9]],
                    mmsi: anomalies
                };

                const data = await dispatch(fetchMapTracks({filter: fetchMapTracksForm})).unwrap();

                const vessels = anomalies.map(mmsi => {
                    const currData = data[mmsi] || {};

                    return {
                        mmsi,
                        name: currData.name || 'Unknown',
                        imo: currData.imo,
                        vesselType: currData.vesselType,
                        flag: currData.flag,
                        lastUpdateTime: moment(currData.timestamp).format('YYYY-MM-DD HH:mm:ss'),
                        position: `${currData.location.lat.toFixed(4)}\n${currData.location.lon.toFixed(4)}`,

                    };
                });

                setVesselData(vessels);
            } catch (error) {
                if (axios.isCancel(error)) {
                    console.log('Request canceled', error.message);
                } else {
                    console.error('Error fetching vessel data:', error);
                }
            } finally {
                setLoading(false);
            }
        };

        if (anomalies.length > 0) {
            fetchVesselData();

        }

        return () => {
            if (cancelToken) {
                cancelToken.cancel('Component unmounted');
            }
        };
        }

    }, [ visible]); //anomaliese bağlı olsun mu?


    const formatPosition = (position: string | undefined) => {
        if (!position) return 'N/A';


        const [latLabel, lonLabel] = position.split('\n');

        return (
            <div style={{ whiteSpace: 'pre-line', fontSize: '14px' }}>
                <div>
                    <span style={{ fontWeight: 'bold' }}>Latitude: </span>{latLabel}
                </div>
                <div>
                    <span style={{ fontWeight: 'bold' }}>Longitude: </span>{lonLabel}
                </div>
            </div>
        );
    };


    const getVesselFlag = (countryCode: string | undefined) => {
        const unknownFlagOutput = (
            <Tooltip title="Unknown">
                <S.UnknownFlag/>
            </Tooltip>
        );

        if (!countryCode) {
            return unknownFlagOutput;
        }

        const countryName = Countries[countryCode as keyof typeof Countries];

        return countryName ? (
            <Tooltip title={countryName}>
                <S.CountryFlag svg countryCode={countryCode}/>
            </Tooltip>
        ) : (
            unknownFlagOutput
        );
    };
    const handleViewVessel = (mmsi) => {
        dispatch(setSelectedMapTrackId(mmsi));
        const adjustedSettings: IMapSettings = { ...mapSettings };
        adjustedSettings.zoomOnSelect = true;
        dispatch(setMapSettings(adjustedSettings));
        setVisible(false);
    }
    const columns = [
        {
            title: 'Vessel',
            key: 'vessel',
            render: (text, vessel) => (
                <div style={{display: 'flex', alignItems: 'center'}}>
                    <img
                        src={`${IMAGE_SERVER_PATH}/vessel?mmsi=${vessel.mmsi}`}
                        alt="vessel"
                        style={{width: '100px', height: 'auto', marginRight: '15px'}}
                    />
                    <div>
                        <div style={{fontSize: '16px', fontWeight: 'bold'}}>
                            {vessel.name}
                        </div>
                        <div>{getVesselFlag(vessel.flag)} {vessel.vesselType}</div>
                    </div>
                </div>
            ),
        },
        {
            title: 'MMSI',
            dataIndex: 'mmsi',
            key: 'mmsi',
        },
        {
            title: 'Last Updated',
            dataIndex: 'lastUpdateTime',
            key: 'lastUpdateTime',
            render: text => text || 'N/A',
        },
        {
            title: 'Position',
            dataIndex: 'position',
            key: 'position',
            render: text => formatPosition(text),
        },
        {
            title: 'Actions',
            key: 'actions',
            render: (text, vessel) => (
                <Tooltip title="View Vessel">
                    <Button
                        type="text"
                        icon={<EyeOutlined style={{color: 'black'}}/>}
                        onClick={() => handleViewVessel(vessel.mmsi)}
                        loading={loading}
                    />
                </Tooltip>
            ),
        },

    ];


    return (
        <Modal
            title="Anomaly Vessels"
            visible={visible}
            onOk={onCancel}
            onCancel={onCancel}
            footer={[
                <Button key="close" onClick={onCancel}>
                    Close
                </Button>
            ]}
            width={800}
        >
            {loading  ? (
                <p>Loading...</p>
            ) : (
                <Table
                    dataSource={vesselData}
                    columns={columns}
                    pagination={false}
                    bordered
                    rowKey="mmsi"
                    style={{marginBottom: '10px'}}
                />
            )}
        </Modal>
    );
}
export default ShowAnomalyModal;
